<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          :to1="'/petoverview/new'"
          :titlemenu2="this.title2"
          :titlemenu3="$t('pet_dropdown_menu_care_routine')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center">
      <PieChart :pet="this.pet" />
      <BarChart :pet="this.pet" />
    </div>
  </div>
</template>
  
  <script>
import HeaderGlobal from "../components/header/HeaderGlobal.vue";
import BarChart from "../components/dataInsightsoverview/BarChart.vue";
import PieChart from "../components/dataInsightsoverview/PieChart.vue";

import { petRecordById } from "../services/petRecordService";

export default {
  components: {
    HeaderGlobal,
    BarChart,
    PieChart,
  },
  data() {
    return { pet: {}, title2: "" };
  },
  async created() {
    const petID = this.$route.params["id"];

    const { data: pet } = await petRecordById(petID);

    console.log(pet);

    this.pet = pet.petRecord;

    this.title2 =
      this.pet.species.id == 1
        ? this.$t("global_dog") + " Record: " + this.pet.callName
        : this.pet.species.id == 2
        ? this.$t("global_cat") + " Record: " + this.pet.callName
        : this.pet.species.id == 3
        ? this.$t("global_ferret") + " Record: " + this.pet.callName
        : this.pet.species.id == 4
        ? this.$t("global_bird") + " Record: " + this.pet.callName
        : this.$t("global_other") + " Record: " + this.pet.callName;
  },
  methods: {},
};
</script>
  
  <style>
</style>